<template>
  <v-row dense class="px-3 py-1 app-bar-borders" >
      <v-col cols="12" v-if="$route.path === '/dashboard'">
        <v-alert
            height="40"
            dense
            type="error"
            color="#D90808"
            class="mb-0"
        >
          <v-row>
            <v-col class="text-left" align-self="center">
              Patient John Doe is having SI thoughts!
            </v-col>
            <v-col class="shrink">
              <v-btn :height="33" color="white" style="background-color: white; color: black; margin-top:-4.5px; margin-right:-12px;">{{$t('resolve')}}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
<!--    <v-col cols="3" v-if="$route.path === '/dashboardsystemadmin'">-->
<!--      <v-select-->
<!--          class="width-text-field2"-->
<!--          hide-details-->
<!--          dense-->
<!--          v-model="selectedMedicalInstitution"-->
<!--          :items="medicalInstitutionsForSystemAdmin"-->
<!--          item-value="id"-->
<!--          item-text="institutionName"-->
<!--          :label="$t('medicalInstitution')"-->
<!--          outlined-->
<!--          :menu-props="{ offsetY: true }"-->
<!--      >-->
<!--      </v-select>-->
<!--    </v-col>-->
    <v-col cols="2" v-if="$route.path === '/sareports' || $route.path === '/dashboardsystemadmin' || $route.path ==='/invoices'">
      <v-text-field
          class="width-text-field1"
          prepend-inner-icon="mdi-magnify"
          hide-details
          dense
          v-model="search"
          :label="$t('search')"
          outlined
      ></v-text-field>
    </v-col>
    <v-col cols="2" v-if="$route.path === '/dashboardsystemadmin'" class="ml-auto">
      <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="-90"
          offset-y
          min-width="auto"
          style="z-index: 1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="selectedDateForSystemAdmin"
              label="Filter date"
              readonly
              v-bind="attrs"
              single-line
              hide-details
              prepend-inner-icon="mdi-calendar"
              dense
              v-on="on"
              outlined
          ></v-text-field>
        </template>
        <v-date-picker :disabled="!enableDatePicker" v-model="selectedDateForSystemAdmin" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
      </v-menu>
    </v-col>
      <v-col cols="2" class="" v-if="$route.path === '/sareports'">
        <v-select
            class="width-text-field2"
            hide-details
            dense
            v-model="selectedMedicalInstitution"
            :items="medicalInstitutions"
            item-value="id"
            item-text="institutionName"
            :label="$t('medicalInstitution')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/organizations' ">
        <v-text-field
            class="width-text-field1"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            v-model="search"
            :label="$t('search')"
            outlined
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="text-right" align-self="center" v-if="$route.path === '/organizations'">
        <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
          <img class="me-3" height="15" :src="addWhite"/>
          <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
        </v-btn>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/offices'">
        <v-text-field
            class="width-text-field1"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            v-model="search"
            :label="$t('search')"
            outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/offices'">
        <v-select
            class="width-text-field2"
            hide-details
            dense
            v-model="selectedMedicalInstitution"
            :items="medicalInstitutions"
            item-value="id"
            item-text="institutionName"
            :label="$t('medicalInstitution')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
      <v-col cols="8" class="text-right" align-self="center" v-if="$route.path === '/offices'">
        <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
          <img class="me-3" height="15" :src="addWhite"/>
          <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
        </v-btn>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/admins'">
        <v-text-field
            class="width-text-field1"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            v-model="search"
            :label="$t('search')"
            outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/admins'">
        <v-select
            class="width-text-field2"
            hide-details
            dense
            v-model="selectedMedicalInstitution"
            :items="medicalInstitutions"
            item-value="id"
            item-text="institutionName"
            :label="$t('medicalInstitution')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/admins'">
        <v-select
            class="width-text-field2"
            hide-details
            dense
            v-model="selectedUserStatusVerifier"
            :items="userStatusVerifiers"
            item-value="lowValue"
            item-text="shortDescription"
            :label="$t('status')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
      <v-col cols="2" v-if="$route.path === '/admins'">
        <v-select
            class="width-text-field2"
            hide-details
            dense
            v-model="selectedRole"
            :items="filteredRoles"
            item-value="id"
            item-text="roleNameForDisplay"
            :label="$t('role')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
      <v-col cols="4" class="text-right" align-self="center" v-if="$route.path === '/admins'">
        <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
          <img class="me-3" height="15" :src="addWhite"/>
          <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
        </v-btn>
      </v-col>
    <v-col v-if="$route.path === '/support'" class="py-5">
    </v-col>
    <v-col v-if="$route.path === '/verifier'" class="py-5">
    </v-col>
    <v-col cols="8" class="text-right" align-self="center" v-if="$route.path === '/verifier'">
      <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
        <img class="me-3" height="15" :src="addWhite"/>
        <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
      </v-btn>
    </v-col>
    <v-col cols="10" class="text-right" align-self="center" v-if="$route.path === '/invoices'">
      <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
        <img class="me-3" height="15" :src="addWhite"/>
        <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
      </v-btn>
    </v-col>

  </v-row>
</template>

<script>
import {
	addWhite,
} from '@/assets';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
	name: 'DashboardCoreDrawer',
	components: {},
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		addWhite: addWhite,
		items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
		search: null,
		// role: 0,
		surgery: -1,
		selectedMedicalInstitution: -1,
		selectedUserStatusVerifier: null,
		selectedRole: null,
    menu1: false,
    selectedDateForSystemAdmin: new Date().toISOString().substr(0, 7),

  }),
	async mounted () {
		await this.getMedicalInstitutions(); // get the list once, after that we use it from the shared state
		await this.getAllVerifiers(); // get the list once, after that we use it from the shared state
		await this.getAllRoles(); // get the list once, after that we use it from the shared state
	},
	computed: {
		...mapState({
			allVerifiers: (state) => state.verifiers.allverifires,
			allRoles: (state) => state.roles.roles,
      enableDatePicker: (state) => state.filterbar.enableDatePicker,
    }),
		...mapGetters({
			// roles: 'roles/getRoles',
			getSpecializations: 'verifiers/getSpecializations',
			medicalInstitutions: 'medicalInstitutions/getMedicalInstitutionsSorted',
			getMedicalTeam: 'filterbar/getMedicalTeam',
		}),
		pathname () {
			return this.$route.name;
		},
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DETAILS_DRAWER', val);
			},
		},
		detailsDrawer () {
			return this.$store.state.detailsDrawer;
		},
		userStatusVerifiers () {
			let userStatusVerifiersList = this.allVerifiers != null ? this.allVerifiers.filter(v => v.rvDomain === 'USER_STATUS') : null;
			if (userStatusVerifiersList != null) {
				userStatusVerifiersList.unshift({ lowValue: -99, shortDescription: 'All', shortDescriptionESP: 'Todo' });
			} else {
				userStatusVerifiersList = [];
				userStatusVerifiersList.unshift({ lowValue: -99, shortDescription: 'All', shortDescriptionESP: 'Todo' });
			}
			return userStatusVerifiersList;
		},
		filteredRoles () {
			const fileteredRolesList = this.allRoles != null ? this.allRoles.filter(r => r.roleName === 'HOSPITAL_ADMIN' || r.roleName === 'ORGANIZATION_ADMIN' || r.roleName === 'CARE_GIVER' || r.id === 9) : [];
			fileteredRolesList.unshift({ id: -99, roleNameForDisplay: 'All', roleNameForDisplayESP: 'Toda' });
			return fileteredRolesList;
		},
	},
	methods: {
		...mapMutations({
			setDrawer: 'SET_DETAILS_DRAWER',
		}),
		async getMedicalInstitutions () {
			await this.$store
				.dispatch('medicalInstitutions/getMedicalInstitutions')
				.then(() => {
					// this.medicalInstitutions = this.medicalInstitutionsFromRepo;
				});
		},
		async getAllVerifiers () {
			// we call this API endpoint only if the store values are null
			if (this.allVerifiers == null) {
				await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
					// this.loading = false;
				});
			}
		},
		async getAllRoles () {
			// we call this API endpoint only if the store values are null
			if (this.allRoles == null) {
				await this.$store.dispatch('roles/getRoles').then(() => {
					// this.loading = false;
				});
			}
		},
		syncronizeDrawers () {
			var parameters = {};
			switch (this.pathname.toLowerCase()) {
			case 'medical staff':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'patients':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'medical team':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'assessments':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'verifier':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'organization':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'office':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'admins':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
        case 'invoices':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
			}
		},
	},
	watch: {
    async selectedDateForSystemAdmin (val) {
      await this.$store.dispatch('filterbar/SystemAdminDate', val);
    },
		async surgery (val) {
			await this.$store.dispatch('filterbar/AppBarSpacialization', val);
		},
		// async role (val) {
		// 	await this.$store.dispatch('filterbar/AppBarRole', val);
		// },
		async doctor (val) {
			await this.$store.dispatch('filterbar/AppBarMedicalTeamDoctor', val);
		},
		async search (val) {
			await this.$store.dispatch('filterbar/AppBarSearch', val);
		},
		async selectedMedicalInstitution (val) {
			await this.$store.dispatch('filterbar/AppBarMedicalInstitution', val);
		},
		async selectedUserStatusVerifier (val) {
			await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', val);
		},
		async selectedRole (val) {
			await this.$store.dispatch('filterbar/AppBarRole', val);
		},
	},
};
</script>

<template>
  <div>
    <v-divider></v-divider>
    <v-row dense v-if="$route.path !== '/dashboard'" class="mx-0 px-0">
      <v-col cols="4" class="mx-0 d-flex">
        <v-text-field
            class="py-1 ml-2"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            v-if=
              "$route.path === '/medicalstaff' ||
              $route.path === '/patients' ||
              $route.path === '/wellnessassessments' ||
              $route.path === '/billings' ||
              $route.path === '/mentalhealthpatient' ||
              $route.path === '/medicalNotesCategory' ||
              $route.path === '/UserBillingSettings' ||
              $route.path === '/billingsettings'||
              $route.path === '/monthlyreview'"
            v-model="search"
            :label="$t('search')"
            outlined
        ></v-text-field>
        <v-text-field
            class="py-1"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            v-if="$route.path === '/medicalteam'"
            v-model="medical"
            :label="$t('search')"
            outlined
        ></v-text-field>
        <v-select
            class="width-text-field2 mt-1 ml-2"
            hide-details
            dense
            v-model="selectedUserStatusVerifier"
            :items="userStatusVerifiers"
            item-value="lowValue"
            v-if="$route.path === '/patients'"
            item-text="shortDescription"
            :label="$t('status')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
        <v-select
            class="width-text-field2 mt-1 ml-2"
            hide-details
            dense
            v-model="selectedTemplateStatus"
            :items="medicalNoteTemplateVerifiers"
            item-value="lowValue"
            v-if="$route.path === '/medicalNotesCategory'"
            item-text="shortDescription"
            :label="$t('status')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
      <v-col cols="2" class="ma-0 px-1"     v-if="$route.path === '/medicalstaff'">
        <v-select
            class="py-1"
            hide-details
            :menu-props="{offsetY:true}"
            dense
            v-model="role"
            :items="roles"
            item-value="id"
            :item-text="$i18n.locale === 'en' ? 'roleNameForDisplay' : 'roleNameForDisplayESP'"
            :label="$t('role')"
            outlined
        >
        </v-select>
      </v-col>
      <v-col cols="2" class="ma-0 px-1"    v-if="$route.path === '/medicalstaff'">
        <v-select
            class="py-1"
            hide-details
            dense
            :menu-props="{offsetY:true}"
            v-model="surgery"
            :item-text="$i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'"
            item-value="lowValue"
            :items="getSpecializations"
            :label="$t('specialization')"
            outlined
        ></v-select>
      </v-col>
      <div style="display:flex; align-items:center; " v-if="$route.path === '/billings' || $route.path === '/monthlyreview'  || $route.path === '/dashboardFacility'" class="justify-end mt-2 ml-auto mr-2">
        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="-90"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="selectedDateForBilling"
                label="Filter date"
                readonly
                v-bind="attrs"
                single-line
                hide-details
                prepend-inner-icon="mdi-calendar"
                dense
                v-on="on"
                class="mr-5"
                outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedDateForBilling" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
        </v-menu>
        <v-btn @click="generateCSV"  v-if="$route.path === '/billings'" color="chips" variant="tonal" class="ml-2 float-right" style="text-transform: capitalize; color:white;"  prepend-icon="mdi-plus">
          <span class="mdi mdi-cloud-download mdi-18px mt-1"></span>
        </v-btn>
        <!--        <div v-if="$route.path === '/billings' && this.roleName === 'HOSPITAL_ADMIN'">-->
        <!--          <v-btn @click="generateBillingDataForHospitalAdmin" class="chips" style="">-->
        <!--            <img class="my-1" height="17px" width="20px" :src="invoice"/>-->
        <!--          </v-btn>-->
        <!--        </div>-->
        <!--        <div v-else-if="$route.path === '/billings' && this.roleName === 'ORGANIZATION_ADMIN'">-->
        <!--          <v-btn @click="generateBillingDataForOrganizationalAdmin" class="primary" style="margin-top:13px; margin-right:20px;">-->
        <!--            <img class="my-1" height="17px" width="20px" :src="invoice"/>-->
        <!--          </v-btn>-->
        <!--        </div>-->
      </div>
      <div style="display:flex; align-items:center; " v-if="$route.path === '/dailyreports'" class="ml-auto mr-2">
        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            offset-y
            nudge-right="-25"
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="selectedDateForReports"
                label="Filter date"
                readonly
                v-bind="attrs"
                single-line
                hide-details
                prepend-inner-icon="mdi-calendar"
                dense
                v-on="on"
                class="mr-5 mt-1 mb-n1"
                outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedDateForReports" color="primary" type="date" no-title scrollable @change="menu1 = false"></v-date-picker>
        </v-menu>
      </div>
      <v-col v-if="$route.path !=='/billings' && $route.path !=='/dailyreports'  && $route.path !=='/monthlyreview' && $route.path !=='/dashboardFacility'" class="d-flex align-center justify-end py-1">
        <v-btn @click="syncronizeDrawers" class="add-button-style">
          <span style="text-transform: capitalize;" class="font-size15"> + {{$t(pathname) }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data: () => ({
    usersActivity: true,
    search: null,
    role: 0,
    selectedDateForBilling: new Date().toISOString().substr(0, 7),
    selectedDateForReports: new Date().toISOString().substr(0, 10),
    medical: null,
    menu1: false,
    selectedUserStatusVerifier: -99,
    selectedTemplateStatus: 3,
    surgery: -1,
    selectedMedicalInstitution: -1,
  }),
  computed: {
    ...mapState({
      userData: (state) => state.authentication.userData,
      allVerifiers: (state) => state.verifiers.allverifires,
    }),
    ...mapGetters({
      roles: 'roles/getRoles',
      getSpecializations: 'verifiers/getSpecializations',
      medicalInstitutions: 'medicalInstitutions/getMedicalInstitutionsSorted',
      getMedicalTeam: 'filterbar/getMedicalTeam',
      appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
      roleName: 'authentication/getRole',
    }),
    pathname () {
      return this.$route.name;
    },
    drawer: {
      get () {
        return this.$store.state.drawer;
      },
      set (val) {
        this.$store.commit('SET_DETAILS_DRAWER', val);
      },
    },
    detailsDrawer () {
      return this.$store.state.detailsDrawer;
    },
    userStatusVerifiers () {
      let userStatusVerifiersList = this.allVerifiers != null ? this.allVerifiers.filter(v => v.rvDomain === 'USER_STATUS') : null;
      if (userStatusVerifiersList != null) {
        userStatusVerifiersList.unshift({ lowValue: -99, shortDescription: 'All', shortDescriptionESP: 'Todo' });
      } else {
        userStatusVerifiersList = [];
        userStatusVerifiersList.unshift({ lowValue: -99, shortDescription: 'All', shortDescriptionESP: 'Todo' });
      }
      return userStatusVerifiersList;
    },
    medicalNoteTemplateVerifiers () {
      let userStatusVerifiersList = this.allVerifiers != null ? this.allVerifiers.filter(v => v.rvDomain === 'MEDICAL_NOTE_CATEGORY_STATUS') : null;
      if (userStatusVerifiersList != null) {
        userStatusVerifiersList.unshift({ lowValue: 3, shortDescription: 'All', shortDescriptionESP: 'Todo' });
      } else {
        userStatusVerifiersList = [];
        userStatusVerifiersList.unshift({ lowValue: 3, shortDescription: 'All', shortDescriptionESP: 'Todo' });
      }
      return userStatusVerifiersList;
    },
  },
  async mounted () {
    if (this.roleName !== 'PARTNER') {
      await this.getAllVerifiers();
    }
    await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', 3);
    await this.$store.dispatch('filterbar/AppBarDate', this.getCurrentMonthAndYear());
  },
  methods: {
    getCurrentMonthAndYear () {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
      return `${year}-${month}`;
    },
    async getAllVerifiers () {
      // we call this API endpoint only if the store values are null
      if (this.allVerifiers == null) {
        await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
          // this.loading = false;
        });
      }
    },
    ...mapMutations({
      setDrawer: 'SET_DETAILS_DRAWER',
    }),
    syncronizeDrawers () {
      var parameters = {};
      switch (this.pathname.toLowerCase()) {
        case 'medical staff':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'patients':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'medical team':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'assessments':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'verifier':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'organization':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'office':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'billing settings':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;

        case 'user billing settings':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'medical note category':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
      }
    },
    async generateBillingDataForHospitalAdmin () {
      const data = { hospitalId: this.userData.hospitalAssignedData.id };
      await this.$store.dispatch('filterbar/billingDataForHospitalAdmin', data);
    },
    async generateBillingDataForOrganizationalAdmin () {
      const data = { organizationId: this.userData.organizationId };
      await this.$store.dispatch('filterbar/billingDataForOrganizationalAdmin', data);
    },
    async generateCSV () {
      const data = { organizationId: this.userData.organizationId };
      await this.$store.dispatch('filterbar/generateCSV', data);
    },
  },
  watch: {
    '$route' (to, from) {
      if (to.path !== '/dashboard') {
        this.search = null;
      }
      if (to.path !== '/dailyreports') {
        // Set selectedDateForReports to today's date
        this.selectedDateForReports = new Date().toISOString().substr(0, 10);
      }
      if (to.path !== '/billings') {
        // Set selectedDateForReports to today's date
        this.selectedDateForBilling = new Date().toISOString().substr(0, 7);
      }
      if (to.path !== '/monthlyreview') {
        // Set selectedDateForReports to today's date
        this.selectedDateForBilling = new Date().toISOString().substr(0, 7);
      }
    },
    async selectedUserStatusVerifier (val) {
      await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', val);
    },
    async selectedTemplateStatus (val) {
      await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', val);
    },
    async usersActivity (val) {
      await this.$store.dispatch('filterbar/usersActivity', val);
    },
    async surgery (val) {
      await this.$store.dispatch('filterbar/AppBarSpacialization', val);
    },
    async role (val) {
      await this.$store.dispatch('filterbar/AppBarRole', val);
    },
    async search (val) {
      await this.$store.dispatch('filterbar/AppBarSearch', val);
    },
    async medical (val) {
      await this.$store.dispatch('filterbar/AppBarMedicalTeamDoctor', val);
    },
    async selectedMedicalInstitution (val) {
      await this.$store.dispatch('filterbar/AppBarMedicalInstitution', val);
    },
    async selectedDateForBilling (val) {
      await this.$store.dispatch('filterbar/AppBarDate', val);
    },
    async selectedDateForReports (val) {
      await this.$store.dispatch('filterbar/AppBarDate', val);
    },
  },
};
</script>
